<template>
  <form class="form">
    <BasePreloader v-if="pending"
                   style="top: -2rem"/>
    <BaseRadioList
      v-model="loginBy"
      :options="authMethods"
      class="form__radio-list"
    />
    <transition name="fade" mode="out-in">
      <BaseInput v-if="loginBy.id === AUTH_METHOD_PHONE"
                 key="3"
                 v-model="phone.value"
                 :error="inputs.login.error"
                 :label="$t('Телефон')"
                 type="tel"
                 :mask="{mask: '+{38} ({\\0}#0)-000-00-00',
                      definitions: {'#': /[1-9]/},
                      unmask: true
                    }"
                 class="form__input"/>

      <BaseInput v-else-if="loginBy.id === AUTH_METHOD_EMAIL"
                 key="4"
                 v-model="email.value"
                 :error="inputs.login.error"
                 :label="$t('Email')"
                 type="email"
                 class="form__input"/>
    </transition>
    <AlertInline :value="overallErrorsMix"/>
    <div class="form__footer">
      <button @click.prevent="onResetClick"
              class="form__btn  base-btn">
        {{$t('Відновити пароль')}}
      </button>
    </div>
  </form>
</template>

<script>
  import formMixins from '../mixins/formMixins'
  import AlertInline from './AlertInline'
  import {
    AUTH_METHOD_PHONE,
    AUTH_METHOD_EMAIL
  } from '@/constants/authMethods'
  import {mapState} from 'vuex'

  export default {
    mixins: [formMixins],
    components: {
      AlertInline
    },
    data() {
      return {
        phone: {
          value: null
        },
        email: {
          value: null
        },
        inputs: {
          login: {
            value: null
          }
        },
        loginBy: null,
        AUTH_METHOD_PHONE,
        AUTH_METHOD_EMAIL
      }
    },
    computed: {
      pending() {
        return this.$store.state.user.pendingAuth
      },
      ...mapState({
        authMethods(state) {
          return state.authMethods.map(item => {
            item = {...item}
            item.name = this.$t(item.name)
            return item
          })
        }
      })
    },
    methods: {
      onResetClick() {
        if(this.loginBy.id === AUTH_METHOD_PHONE) {
          this.inputs.login.value = this.phone.value
        } else if (this.loginBy.id === AUTH_METHOD_EMAIL) {
          this.inputs.login.value = this.email.value
        }
        this.resetErrorsMix(this.inputs)

        const sendData = this.createSendDataMix(this.inputs)
        this.$store.dispatch('user/resetPassword', sendData).then((response) => {
          this.$vModal.close('auth-reset-password')
          this.$vModal.open('message', {
            message: response.message,
          })

        }).catch(error => {
          this.handlerErrorMix(error, this.inputs)
        })
      }
    },
    created() {
      this.loginBy = this.authMethods[0]
    }
  }
</script>

<style lang="less">

</style>
