<template>
  <form class="form" @submit.prevent="onRegistrationClick">
    <BasePreloader v-if="pending"
                   style="top: -2rem"/>
    <BaseInput v-model="inputs.name.value"
               :error="inputs.name.error"
               :label="$t(`Ім'я`)"
               name="name"
               type="text"
               class="form__input"/>
    <BaseInput v-model="inputs.email.value"
               :error="inputs.email.error"
               label="Email"
               name="email"
               class="form__input"/>
    <BaseInput v-model="inputs.phone.value"
               :error="inputs.phone.error"
               :label="$t('Телефон')"
               type="tel"
               name="phone"
               :mask="{mask: '+{38} ({\\0}#0)-000-00-00',
                      definitions: {'#': /[1-9]/},
                      unmask: true
                    }"
               class="form__input"/>
    <BaseInput v-model="inputs.password.value"
               :error="inputs.password.error"
               :label="$t('Пароль')"
               type="password"
               name="password"
               class="form__input"/>
    <AlertInline :value="overallErrorsMix" class="_warning"/>
    <div class="form__footer">
      <button type="submit"
              class="form__btn  base-btn">
        {{$t('Зареєструватись')}}
      </button>
      <button type="button" @click.prevent="onLoginClick"
              class="form__btn  base-btn  _secondary">
        {{$t('Вхід')}}
      </button>
    </div>
    <Note class="form__note">
      <p>
        {{$t('Реєструючись ви погоджуєтесь з')}}
        <router-link :to="{name: 'policy'}" target="_blank">{{$t('правилами сайту')}}</router-link>
      </p>
    </Note>
  </form>
</template>

<script>
  import formMixins from '../mixins/formMixins'
  import AlertInline from './AlertInline'
  import Note from './Note'

  export default {
    mixins: [formMixins],
    components: {
      AlertInline,
      Note
    },
    data() {
      return {
        inputs: {
          name: {
            value: null
          },
          email: {
            value: null
          },
          phone: {
            value: null
          },
          password: {
            value: null
          }
        }
      }
    },
    computed: {
      pending() {
        return this.$store.state.user.pendingAuth
      }
    },
    methods: {
      onRegistrationClick() {
        this.resetErrorsMix(this.inputs)

        const sendData = this.createSendDataMix(this.inputs)
        this.$store.dispatch('user/registration', sendData).then(() => {
          this.$vModal.close('auth-registration')
        }).catch(error => {
          this.handlerErrorMix(error, this.inputs)
        })
      },
      onLoginClick() {
        this.$vModal.close()
        this.$vModal.open('auth-login')
      }
    }
  }
</script>

<style lang="less">

</style>
