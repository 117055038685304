<template>
  <div class="note">
    <div class="note__ico">
      <BaseSvg name="ico-info"/>
    </div>
    <div class="note__text  text">
      <slot>
        <p>{{value}}</p>
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: String
    }
  }
</script>

<style lang="less">

</style>
