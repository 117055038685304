<template>
  <form class="form" @submit.prevent="onLoginClick">
    <BasePreloader v-if="pending" style="top: -2rem" />
    <BaseRadioList v-model="loginBy" :options="authMethods" class="form__radio-list" />

    <transition name="fade" mode="out-in">
      <BaseInput
        v-if="loginBy.id === AUTH_METHOD_PHONE"
        key="1"
        v-model="phone.value"
        :error="inputs.login.error"
        :label="$t('Телефон')"
        type="tel"
        :mask="{ mask: '+{38} ({\\0}#0)-000-00-00', definitions: { '#': /[1-9]/ }, unmask: true }"
        class="form__input"
      />

      <BaseInput
        v-else-if="loginBy.id === AUTH_METHOD_EMAIL"
        key="2"
        v-model="email.value"
        :error="inputs.login.error"
        :label="$t('Email')"
        type="email"
        class="form__input"
      />
    </transition>

    <BaseInput
      v-model="inputs.password.value"
      :error="inputs.password.error"
      :label="$t('Пароль')"
      type="password"
      class="form__input"
    />
    <div class="form__under-input">
      <a href="#" @click.prevent="onResetPasswordClick">{{ $t("Забули пароль?") }}</a>
    </div>
    <AlertInline :value="overallErrorsMix" class="_warning" />
    <div class="form__footer">
      <button type="submit" class="form__btn base-btn">
        {{ $t("Увійти") }}
      </button>
      <button type="button" @click.prevent="onRegistrationClick" class="form__btn base-btn _secondary">
        {{ $t("Реєстрація") }}
      </button>
    </div>

    <div class="form__login-with">
      <div class="form__login-with-or">{{ $t("Або увійти через") }}</div>
      <div class="form__login-with-inner">
        <!--        <a :href="`${baseURL}/fb/redirect?redirect=${locationHref}`"-->
        <!--           class="form__login-with-btn _fb"-->
        <!--        >-->
        <!--          <i>-->
        <!--            <BaseSvg name="ico-fb-color"/>-->
        <!--          </i>-->
        <!--          <span>Facebook</span></a>-->
        <a :href="`${baseURL}/auth/redirect?redirect=${locationHref}`" class="form__login-with-btn _google">
          <i>
            <BaseSvg name="ico-google-color" />
          </i>
          <span>Google</span></a
        >
      </div>
    </div>
  </form>
</template>

<script>
import { baseURL } from "@/axios";
import { mapState } from "vuex";
import formMixins from "../mixins/formMixins";
import AlertInline from "./AlertInline";
import { AUTH_METHOD_PHONE, AUTH_METHOD_EMAIL } from "@/constants/authMethods";

export default {
  mixins: [formMixins],
  props: {
    redirect: Object,
  },
  components: {
    AlertInline,
  },
  data() {
    return {
      baseURL,
      phone: {
        value: null,
      },
      email: {
        value: null,
      },
      inputs: {
        login: {
          value: null,
        },
        password: {
          value: null,
        },
      },
      loginBy: null,
      AUTH_METHOD_PHONE,
      AUTH_METHOD_EMAIL,
    };
  },
  computed: {
    locationHref() {
      return `${window.location.origin}/${this.$route.params.locale}/account/profile`;
    },
    pending() {
      return this.$store.state.user.pendingAuth;
    },
    ...mapState({
      authMethods(state) {
        return state.authMethods.map((item) => {
          item = { ...item };
          item.name = this.$t(item.name);
          return item;
        });
      },
    }),
  },
  methods: {
    onLoginClick() {
      if (this.loginBy.id === AUTH_METHOD_PHONE) {
        this.inputs.login.value = this.phone.value;
      } else if (this.loginBy.id === AUTH_METHOD_EMAIL) {
        this.inputs.login.value = this.email.value;
      }

      this.resetErrorsMix(this.inputs);

      const sendData = this.createSendDataMix(this.inputs);

      this.$store
        .dispatch("user/login", sendData)
        .then(() => {
          this.$vModal.close("auth-login");
          if (this.redirect) {
            this.$router.push(this.redirect);
          }
        })
        .catch((error) => {
          this.handlerErrorMix(error, this.inputs);
        });
    },
    onRegistrationClick() {
      this.$vModal.close();
      this.$vModal.open("auth-registration");
    },
    onResetPasswordClick() {
      this.$vModal.close();
      this.$vModal.open("auth-reset-password");
    },
  },
  created() {
    this.loginBy = this.authMethods[0];
  },
};
</script>

<style lang="less"></style>
